import { IS_B2B } from '@/domains/core/settings/constants';
import {
  detailsApp,
  detailsProApp,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  titleApp,
} from '@/domains/retention/Footer/translations';

export const getAppsData = (forceB2B: boolean) =>
  IS_B2B || forceB2B
    ? {
        appStoreUrl: footerAppStoreMamomanoProUrl,
        playStoreUrl: footerPlaymarketMamomanoProUrl,
        title: titleApp,
        subTitle: detailsProApp,
      }
    : {
        appStoreUrl: footerAppStoreMamomanoUrl,
        playStoreUrl: footerPlaymarketMamomanoUrl,
        title: titleApp,
        subTitle: detailsApp,
      };
